import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Drag from "../../../components/Drag";
import ColorPicker from "../../../components/ColorPicker";
import http from "../../../utils/http";

const { REACT_APP_API_SERVER } = process.env;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 500,
    maxWidth: 500,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
});

function getStyles(name, that) {
  return {
    fontWeight:
      that.state.tags.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}

class AddressForm extends PureComponent {
  state = {
    tags: [],
    name: '',
    description: '',
    cares: '',
    active: true,
    image: [],
    categories: [],
    colors: [],
    imageUrl: null
  };

  componentDidMount() {
    const { match } = this.props;
    http.get('categories', false).then(res => {
      if (res.success) this.setState({ categories: res.categories });
    })
    if (match.params.id) {
      http.get(`product/${match.params.id}`, false).then(res => {
        if (res.success) {
          const { name, description, cares, categories, image, active, colors } = res.product;
          console.log(colors, 'COLO');
          const tags = categories.map(sn => sn.name);
          this.setState({ name, cares, description, tags, imageUrl: image, active: active ? true : false, colors });
        }
      });
    }
  }

  handleChange = name => event => {
    let { value } = event.target;
    if (name === 'active') {
      value = value === 'true' ? false : true;
    }
    this.setState({
      [name]: value,
    });
  };

  setImg(files) {
    this.setState({image: files});
  }

  changeColor(value, index) {
    const { colors } = this.state;
    const newColors = [...colors];
    newColors[index] = {...newColors[index], value: value};
    this.setState({colors: newColors});
  }

  newColor() {
    const { colors } = this.state;
    this.setState({colors: [...colors, { value: "#000" }]});
  }

  deleteColor(index) {
    const { colors } = this.state;
    const newColors = [...colors];
    newColors.splice(index, 1);
    this.setState({colors: newColors});
  }

  send() {
    const { match, history } = this.props;
    const { name, description, cares, image, tags, categories, active, colors } = this.state;

    const cats = tags.map(tg => categories.find(ct => ct.name === tg).id)
    let formData = new FormData(); 
    formData.append('name', name);
    formData.append('description', description);
    formData.append('cares', cares);
    formData.append('active', active);
    if(image.length) formData.append('image', image[0]);
    formData.append('categories', cats);
    formData.append('colors', JSON.stringify(colors));
    if (match.params.id) {
      http.put(`product/${match.params.id}`, formData, false).then(res => {
        if (res.success) {
          // notify.show('Operación exitosa!', "custom", 5000, myColor);
          history.goBack();
        }
      })
    } else {
      http.post('product', formData, false).then(res => {
        if (res.success) {
          // notify.show('Operación exitosa!', "custom", 5000, myColor);
          history.goBack();
        }
      })
    }
  }

  render() {
    const { classes } = this.props;
    const { tags, name, description, cares, categories, imageUrl, active, colors } = this.state;

    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Producto
        </Typography>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label="Nombre"
              fullWidth
              variant="outlined"
              autoComplete="fname"
              value={name}
              onChange={this.handleChange('name')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="address1"
              name="address1"
              label="Descripción"
              fullWidth
              variant="outlined"
              autoComplete="billing address-line1"
              value={description}
              onChange={this.handleChange('description')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address2"
              name="address2"
              label="Cuidados"
              fullWidth
              variant="outlined"
              autoComplete="billing address-line2"
              value={cares}
              onChange={this.handleChange('cares')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="select-multiple-chip">Características</InputLabel>
              <Select
                multiple
                value={tags}
                onChange={this.handleChange("tags")}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map((value, index) => (
                      <Chip key={`chi-${index}`} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {categories.map(item => (
                  <MenuItem key={`mi-${item.id}`} value={item.name} style={getStyles(item.name, this)}>
                    {item.name}
                  </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <h3>Colores</h3>
            {colors.map((col, index) =>
              <div key={`color-${index}`}>
                <ColorPicker
                  onChange={this.changeColor.bind(this)}
                  color={col}
                  index={index}
                  deleteColor={this.deleteColor.bind(this)}
                />
              </div>
            )}
            <div className="button-container">
              <Button onClick={() => this.newColor()} variant="contained" color="primary" className="button-form">
                Nuevo
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Drag setImg={this.setImg.bind(this)}/>
          </Grid>
          {imageUrl ? (
          <Grid item xs={12}>
            <div className="product-img" style={{
              backgroundImage: `url(${REACT_APP_API_SERVER}/products/${imageUrl})`
            }}></div>
          </Grid>
          ) : null}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={active}
                  onChange={this.handleChange('active')}
                  value={active.toString()}
                  color="primary"
                />
              }
              label="Activo"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="button-container">
              <Button onClick={() => this.send()} variant="contained" color="primary" className="button-form">
                Guardar
                <Icon className={classes.rightIcon}>send</Icon>
              </Button>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AddressForm);
