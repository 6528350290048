import axios from 'axios';
// import RNFetchBlob from 'rn-fetch-blob';
import { API_HEADERS } from '../constants/config';
require('dotenv').config();

const { REACT_APP_API_ROOT } = process.env;

const getUserToken = () => new Promise((res, rej) => {
  res(localStorage.getItem('token'))
})
 
function generateHeaders(includeToken) {
  return getUserToken().then(token => {
    if (!includeToken) return API_HEADERS;
    if (!token) return API_HEADERS;
    return {
      ...API_HEADERS,
      Authorization: `Bearer ${token}`,
    };
  });
}

const http = {
  get: (url, includeToken = true) =>
    generateHeaders(includeToken).then(headers =>
      axios
        .get(`${REACT_APP_API_ROOT}/${url}`, { headers })
        .then(response => response.data)
        .catch(err => {
          console.log(err.response);
        })
    ),
  post: (url, data, includeToken = true) =>
    generateHeaders(includeToken).then(headers =>
      axios
        .post(`${REACT_APP_API_ROOT}/${url}`, data, { headers })
        .then(response => response.data)
        .catch(err => {
          console.log(err.response);
        })
    ),
  formData: (url, data, includeToken = true) =>
    axios
      .post(`${REACT_APP_API_ROOT}/${url}`, data, { ...API_HEADERS, 'content-type': 'multipart/form-data' })
      .then(response => response.data)
      .catch(err => {
        console.log(err.response);
      }),
  put: (url, data = {}, includeToken = true) =>
    generateHeaders(includeToken).then(headers =>
      axios
        .put(`${REACT_APP_API_ROOT}/${url}`, data, { headers })
        .then(response => response.data)
        .catch(err => {
          console.log(err.response);
        })
    ),
  // fetchBlob: (url, data, includeToken = true) =>
  //   generateHeaders(includeToken).then(headers =>
  //     RNFetchBlob.fetch(
  //       'POST',
  //       `${REACT_APP_API_ROOT}/${url}`,
  //       {
  //         ...headers,
  //         'Content-Type': 'multipart/form-data',
  //       },
  //       data
  //     )
  //       .then(res => res.json())
  //       .catch(err => {
  //         console.log(err);
  //       })
  //   ),
};

export default http;
