import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Link } from "react-router-dom";
import Fab from '@material-ui/core/Fab';
import http from "../../../utils/http";
// import "./styles.scss";

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

class SimpleTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
    };
  }
  componentDidMount() {
    http.get('categories', false).then(res => {
      if (res.success) this.setState({ categories: res.categories });
    })
  }
  render() {
    const { classes } = this.props;
    const { categories } = this.state;
  
    return (
      <Paper className={classes.root}>
        <div className="button-container">
          <Link offset="0" to="/admin/category">
            <Button variant="contained" color="primary" className="button-form">
              Nueva Categoria
              <Icon className={classes.rightIcon}>send</Icon>
            </Button>
          </Link>
        </div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="left">Nombre</TableCell>
              <TableCell align="left">Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {index}
                </TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">
                  <Link to={`/admin/category/${row.id}`}>
                    <Fab size="small" color="secondary" aria-label="Edit" className={classes.fab}>
                      <Icon>edit_icon</Icon>
                    </Fab>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleTable);
