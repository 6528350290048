import React from "react";

import "./styles.scss";

const CompanyImgSection = ({ data }) => {
  const { text, title } = data;
  return (
    <div className="container" >
      <div className="no-img-content">
        <div className="title">{title}</div>
        {
          text.map(par => (
            <p className="paragraph">{par}</p>
          ))
        }
      </div>
    </div>
  )
}

export default CompanyImgSection;
