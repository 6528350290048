export default [
    {
      title: 'Productos',
      url: '/admin/products'
    },
    {
      title: 'Categorias',
      url: '/admin/categories'
    },
  ];
