import React, { Component } from "react";
import Menu from '@material-ui/icons/Menu';

import "./styles.scss";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    };

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({ scrollY: window.scrollY });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("scroll", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateDimensions);
  }

  render() {
    const { changeMenu } = this.props;
    const { scrollY } = this.state;

    return (
      <div className={scrollY > 0 ? "header scrollable" : "header"}>
        <div className="header-content" id="home">
          <div className="logo-wrapper">
            <button className="menu-button" onClick={() => changeMenu()}>
              <Menu />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
