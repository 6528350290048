import React, { Component } from 'react';
import { Route } from 'react-router-dom';

// Components
import App from '../App';
import Home from '../views/landing/Home';
// import Page404 from 'components/Page404';
import Company from '../views/landing/Company';
import Processes from '../views/landing/Processes';
import Products from '../views/landing/Products';
import Contact from '../views/landing/Contact';

class AppRoutes extends Component {
  render() {
    const path = "/";
    return (
      <App>
        <Route exact path={`${path}company/`} component={Company} />
        <Route exact path={`${path}processes/`} component={Processes} />
        <Route exact path={`${path}products/`} component={Products} />
        <Route exact path={`${path}contact/`} component={Contact} />
      </App>
    )
  }
}

export default AppRoutes;