import React, { PureComponent } from "react";
import Banner from "../../../components/Banner";
import Product from "../../../components/Product";
import Modal from "../../../components/Modal";
import Header from '../../../components/Global/Header';
import Footer from '../../../components/Global/Footer';
import http from "../../../utils/http";
import items from '../../../data/topMenu';
import Helmet from 'react-helmet';

import "./styles.scss";

class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      product: {},
      modalVisible: false
    };
  }

  componentDidMount() {
    http.get('products', false).then(res => {
      if (res.success) {
        const products = res.products.filter(pr => pr.active);
        this.setState({ products: products.filter(pr => pr.categories.some(ct => ct.home)) });
      }
    })
  }

  changeModal() {
    const { modalVisible } = this.state;
    this.setState({ modalVisible: !modalVisible });
  }

  selectProduct(product) {
    this.changeModal();
    this.setState({product});
  }

  render() {
    const { products, product, modalVisible } = this.state;
    const title = 'El Pionero';
    return (
      <div>
        <Helmet>
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        
          <title>{title}</title>
        </Helmet>
        <Header
          items={items}
        />
        <div className="content">
          <Banner />
          <div className="news" >
          <Modal visible={modalVisible} product={product} handler={this.changeModal.bind(this)}/>
            <div className="news-container">
              <div className="title">Novedades</div>
              <div className="paragraph">Conoce las ultimas novedades en telas de tejido de punto que traemos para ti</div>
              <div className="product-wrapper">
                {products.map((item, i) => (
                  <Product item={item} key={`pr-${i}`} selectProduct={this.selectProduct.bind(this)} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
