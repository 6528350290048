import React, { PureComponent } from 'react';
import "./styles.scss";

class RadioBtn extends PureComponent {
  handleClick() {
    const { handler, index } = this.props;
    handler(index);
  }

  render() {
    const { isChecked, value, text } = this.props;
    return (
      <div className="radio-btn-group" onClick={this.handleClick.bind(this)}>
        <div className={isChecked ? "radio-btn checked" : "radio-btn unchecked"} data-value={value}>
          <div className="dot"></div>
        </div>
        <label className="option-text">{text.toUpperCase()}</label>
      </div>
    );
  }
}

export default RadioBtn;
