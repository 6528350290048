import React from "react";

import "./styles.scss";

const CompanyImgSection = ({ banner, data }) => {
  const { paragraphs, title } = data;
  return (
    <div className="banner" >
      <div className="banner-container img-section-container" style={{
        backgroundImage: `url(${banner})`
      }}>
        <div className="img-section-layer">
          <div className="title">{title}</div>
          <div className="img-section-wrapper">
            <div className="banner-content">
              <p className="banner-text">
                {
                  paragraphs.map(par => (
                    <p className="paragraph">{par}</p>
                  ))
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyImgSection;
