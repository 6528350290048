import React from 'react'
import ColorPicker from 'material-ui-color-picker'
import Color from "../Color";
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import Icon from '@material-ui/core/Icon';
import "./styles.scss";

const ColorPickerComponent = ({ color = { value: "#000" }, onChange, index, deleteColor }) => {
  console.log(color);
  return (
    <div className="color-picker">
      <ColorPicker
        name='color'
        defaultValue='#000'
        value={color.value}
        onChange={col => onChange(col, index)}

      />
      <Color color={color.value} />
      <Fab size="small" color="secondary" aria-label="Edit" onClick={() => deleteColor(index)}>
        <DeleteIcon />
      </Fab>
    </div>
  )
}

export default ColorPickerComponent;
