import React from "react";
import "./styles.scss";

const Maps = () => (
  <div className="maps" >
    <div className="fact-container">
      <div className="label-form bold">Ubícanos:</div>
    </div>
    <div className="maps-container">
      <div className="map">
        <iframe title="first-location" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
          src="https://maps.google.com/maps?q=-12.050116%2C%20-76.956838&amp;t=m&amp;z=14&amp;output=embed&amp;iwloc=near"
          aria-label="-12.0487197, -76.9594387" width="100%" height="340px"></iframe>
        <div className="fact-container">
          <div className="label-form bold">FÁBRICA</div>
          <div>Teléfono: 362-5485 / 362-5965</div>
        </div>
      </div>
      <div className="map">
        <iframe title="second-location" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
          src="https://maps.google.com/maps?q=-12.067676%2C%20-77.013559&amp;t=m&amp;z=14&amp;output=embed&amp;iwloc=near"
          aria-label="-33.445863, -70.679492" width="100%" height="340px"></iframe>
        <div className="fact-container">
          <div className="label-form bold">TIENDA</div>
          <div>Teléfono: 324-7159</div>
          <div>Whatsapp: 932 566 168</div>
        </div>
      </div>
    </div>
    <div className="addresses-container">
      <div className="label-form bold">Puntos de venta:</div>
      <ul className="address-list">
        <li>
          <div className="address">
            <div className="bold">Gamarra</div>
            <div>Jr. San Cristóbal 1580 1er. Sótano Int. 023</div>
            <div>(Galería La Torre de Sucre) - La Victoria</div>
          </div>
        </li>
        <li>
          <div className="address">
            <div className="bold">Santa Anita</div>
            <div>Av. Colectora Industrial 121 A</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
);

export default Maps;
