import React from 'react';
import './bootstrap';
import './index.css';
import AppRoutes from './routes';
import * as serviceWorker from './serviceWorker';
import { HashRouter as Router } from 'react-router-dom';
// import { hydrate, render } from "react-dom";
import ReactDOM from 'react-dom';
require('dotenv').config();

ReactDOM.render(
  <Router>
    <AppRoutes />
  </Router>,
document.getElementById('root'));

// const rootElement = document.getElementById("root");

// if (rootElement.hasChildNodes()) {
//   hydrate(
// 	<Router>
//     <AppRoutes />
//   </Router>, rootElement);
// } else {
//   render(
// 	<Router>
//     <AppRoutes />
//   </Router>, rootElement);
// }

// if (module.hot) {
//   module.hot.accept('./routes', () => {
//     const NextApp = require('./routes').default;
//     render(<NextApp />, rootElement);
//   });
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
