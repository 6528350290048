export default {
  history: {
    title: "NUESTRA HISTORIA",
    paragraphs: [
      "Textil El Pionero fue fundado en el año 1993, a lo largo de estos años hemos  ido desarrollando y fabricando tejidos de punto en poliéster y algodón, siempre en un continuo proceso de mejora en nuestro productos buscando la satisfacción de nuestros clientes.",
      "Nuestra amplia experiencia en el sector, moderna maquinaria y personal calificado, hacen de nuestros telares productos de excelente calidad.",
      "A través de los años nos hemos posicionado como los líderes del mercado en el tejido polar y tejidos para prendas infantiles."
    ]
  },
  mission: {
    title: "NUESTRA MISIÓN",
    text: [
      "Contar con amplio portafolio de tejidos de punto de la mejor calidad de acuerdo con las tendencias del mercado, superando las expectativas de nuestros clientes.",
      "Buscar generar un compromiso e identificación con nuestros colaboradores."
    ]
  },
  team: {
    title: "NUESTRO EQUIPO",
    paragraphs: [
      "Contamos con un equipo de colaboradores con experiencia en el rubro textil que nos vienen acompañando muchos años."
    ]
  },
  vision: {
    title: "NUESTRA VISIÓN",
    text: [
      "Ser una empresa referente en el rubro textil por la innovación y calidad de nuestros tejidos de punto."
    ]
  },
};
