import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Landing from './landing';
import Admin from './admin';
import Home from '../views/landing/Home';
// import Page404 from 'components/Page404';

class AppRoutes extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path="/admin" component={Admin} />
          <Route component={Landing} />
        </Switch>
      </div>
    )
  }
}

export default AppRoutes;
