import React, { PureComponent } from "react";
import ContactForm from "../../../components/ContactForm";
import Maps from "../../../components/Map";
import "./styles.scss";

class Contact extends PureComponent {
  render() {
    return (
      <div className="container">
        <div className="contact-container" >
          <ContactForm />
          <Maps />
        </div>
      </div>
    );
  }
}

export default Contact;
