import React from "react";
import processes from "../../../data/processes";
import  Process from "../../../components/Process";
import "./styles.scss";

const Processes = () => (
  <div className="container" >
    <div className="news-container">
      <div className="title">Procesos</div>
      <div className="paragraph">
        Nuestros tejidos pasan por un riguroso control de calidad en cada parte del proceso, las pruebas realizadas nos dan la garantía de poder entregar un excelente producto que sobresale a los estándares del mercado nacional.
      </div>
      <div className="product-wrapper">
        {processes.map((item, i) => (
          <Process key={`pr-${i}`} item={item}/>
        ))}
      </div>
    </div>
  </div>
);

export default Processes;
