import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import "./styles.scss";

class Footer extends Component {
  render() {
    const { copyright = "&copy; 2019 El Pionero. Todos los derechos reservados" } = this.props;

    return (
      <div className="footer">
        <div className="footer-content">
          <div className="footer-logo-wrapper">
            <Link offset="0" to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <p dangerouslySetInnerHTML={{ __html: copyright }} />
        </div>
      </div>
    );
  }
}

export default Footer;
