import React from 'react';
import "./styles.scss";

const Color = ({ color }) => {
  console.log(color);
  return (
    <div className="color-container">
      <div className="color-content" style={{ backgroundColor: color }} />
    </div>
  )
}

export default Color;
