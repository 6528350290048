import React, { PureComponent } from "react";
import Product from "../../../components/Product";
import RadioBtn from "../../../components/RadioBtn";
import Modal from "../../../components/Modal";
import http from "../../../utils/http";

import "./styles.scss";

class Products extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      product: {},
      categories: [],
      filteredProducts: [],
      selectedIndex: null,
      selectedValue: null,
      displayMenu: false,
      modalVisible: false
    };
  }

  componentDidMount() {
    http.get('products', false).then(res => {
      const products = res.products.filter(pr => pr.active);
      if (res.success) this.setState({ filteredProducts: products, products });
    })
    http.get('categories', false).then(res => {
      if (res.success) this.setState({ categories: [{id: 0, name: "Todas"}, ...res.categories] });
    })
  }

  toggleRadioBtn(index){
    const { categories, products } = this.state;
    const filteredProducts = index ? products.filter(pr => pr.categories.map(ct => ct.id).includes(parseInt(index))) : products;

    this.setState({
      selectedIndex: index,
      selectedValue: categories.find(cat => cat.id === index).name,
      categories,
      filteredProducts
    });
  }

  changeModal() {
    const { modalVisible } = this.state;
    this.setState({ modalVisible: !modalVisible });
  }

  selectProduct(product) {
    this.changeModal();
    this.setState({product});
  }

  showMenu() {
    const { displayMenu } = this.state;
    this.setState({displayMenu: !displayMenu});
  }

  render() {
    const { filteredProducts, categories, selectedIndex, displayMenu, modalVisible, product } = this.state;

    const allOptions = categories.map((option, i) => {
      return <RadioBtn key={`prx-${i}`} isChecked={(selectedIndex === option.id)} text={option.name} value={option.name} index={option.id} handler={this.toggleRadioBtn.bind(this)} />
    });

    return (
      <div className="container" >
        <Modal visible={modalVisible} product={product} handler={this.changeModal.bind(this)}/>
        <div className="title-container">
          <div className="title">CATÁLOGO DE TELAS</div>
          <div className="filter-container">
            <button className="banner-button" onClick={() => this.showMenu()}>FILTROS</button>
            <div className={displayMenu ? "nav-responsive nav-show" : "nav-responsive"}>{allOptions}</div>
          </div>
        </div>
        <div className="categories-nav">{allOptions}</div>
        <div className="product-wrapper">
          {filteredProducts.map((item, i) => (
            <Product item={item} key={`pr-${i}`} selectProduct={this.selectProduct.bind(this)}/>
          ))}
        </div>
      </div>
    );
  }
}

export default Products;
