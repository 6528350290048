import React from "react";
import banner from "../../assets/images/banner.jpg";
import { Link } from "react-router-dom";
import "./styles.scss";

const Banner = () => (
  <div className="banner" >
    <div className="banner-container" style={{
      backgroundImage: `url(${banner})`
    }}>
      <div className="banner-layer">
        <div className="banner-content">
          <p className="banner-text">
            "Ofrecer tejidos de calidad y vanguardia es lo que nos apasiona hace más de 25 años"
          </p>
          <Link to={'products'}
          >
            <div className="banner-button-container">
              <div className="banner-button">Conoce nuestros productos</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default Banner;
