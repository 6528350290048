import React, { PureComponent } from "react";
import Color from "../Color";
import CircularProgress from '@material-ui/core/CircularProgress';

import "./styles.scss";

const { REACT_APP_API_SERVER } = process.env;

class Modal extends PureComponent {
  closeModal() {
    const { handler } = this.props;
    handler();
  }

  render() {
    const { product, visible } = this.props;
    if (!product) return <CircularProgress />
    return (
      <div className={visible ? "modal visible" : "modal"} >
        <div className="modal-container">
          <button onClick={this.closeModal.bind(this)} className="close">&times;</button>
          {/* <button className="plus" onClick={this.closeModal.bind(this)}><FontAwesomeIcon icon={faPlus} /></button> */}
          <div className="modal-title">{product.name}</div>
          <div className="modal-layer">
            <div className="banner-imgs-container">
              <div className="img-container">
                <img className="modal-img" src={`${REACT_APP_API_SERVER}/products/${product.image}`} alt="logo" />
              </div>
            </div>
            <div className="modal-content">
              <div className="product-title">DESCRIPCIÓN</div>
              <p className="paragraph">{product.description}</p>
              <div className="product-title">COLORES</div>
              <div className="color-container">
                {(product.colors || []).map(col => <Color color={col.value} />)}
              </div>
              <div className="product-title">PROPIEDADES DE LA TELA</div>
              <div className="product-title">CUIDADOS</div>
              <p className="paragraph">{product.cares}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
