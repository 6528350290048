import React, { Component } from "react";
import { Link } from "react-router-dom";

import logo from "../../../assets/images/logo.png";
import menu from "../../../assets/images/menu-black.png";

import "./styles.scss";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this.displayMenu = this.displayMenu.bind(this);
  }

  updateDimensions() {
    this.setState({ scrollY: window.scrollY });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("scroll", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateDimensions);
  }

  displayMenu() {
    const { showMenu } = this.state;

    this.setState({ showMenu: !showMenu });
  }

  render() {
    const { items } = this.props;
    const { scrollY, showMenu } = this.state;

    return (
      <div className={scrollY > 0 ? "header scrollable" : "header"}>
        <div className="header-content" id="home">
          <div className="logo-wrapper">
            <Link offset="0" to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="menu-options">
            <ul>
              {items &&
                items.map((item, key) => (
                  <li key={`hr-${key}`}>
                    <Link
                      offset={[0, 2].includes(key) ? "105" : 0}
                      to={item.url}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
          <div className="mobile-menu">
            <a className="menu-icon" onClick={this.displayMenu}>
              <img src={menu} alt="menu" />
            </a>
            <div>
              <ul className={showMenu ? "display-menu" : "display-menu hide"}>
                {items &&
                  items.map((item, key) => (
                    <li className="display-item" key={`i-${key}`}>
                      <Link
                        to={item.url}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
