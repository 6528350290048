import React, { PureComponent } from "react";

class Home extends PureComponent {
  render() {
    return (
      <div>
          Administrador El Pionero
      </div>
    );
  }
}

export default Home;
