import React from "react";

import "./styles.scss";

const Process = ({ item }) => (
  <div className="process" >
    <div className="process-content">
      <div className="process-img" style={{
        backgroundImage: `url(${item.img})`
      }}></div>
      <div className="process-info-container">
        <div className="process-title">{item.name}</div>
        <div className="product-text">{item.description}</div>
      </div>
    </div>
  </div>
);

export default Process;
