import React from "react";
import plus from "../../assets/images/plus.png";
import "./styles.scss";

const { REACT_APP_API_SERVER } = process.env;

const Product = ({ item, selectProduct }) => {
  return (
    <div className="product">
      <div className="product-content">
        <div className="product-img" style={{
          backgroundImage: `url(${REACT_APP_API_SERVER}/products/${item.image})`
        }}>
          <button onClick={() => selectProduct(item)} className="plus"><img src={plus} alt="logo" /></button>
        </div>
        <div className="product-title">{item.name}</div>
        <div className="product-text">{item.description}</div>
      </div>
    </div>
  )
};

export default Product;
