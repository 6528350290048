import React from "react";
import CompanyImgSection from "../../../components/CompanyImgSection";
import CompanyNoImgSection from "../../../components/CompanyNoImgSection";
import history from "../../../assets/images/history.png";
import team from "../../../assets/images/team.png";
import data from "../../../data/company";

const Company = () => (
  <div className="company" >
    <CompanyImgSection banner={history} data={data.history}/>
    <CompanyNoImgSection data={data.mission}/>
    <CompanyImgSection banner={team} data={data.team}/>
    <CompanyNoImgSection data={data.vision}/>
  </div>
);

export default Company;
