export default [
    {
      title: 'LA EMPRESA',
      url: '/company'
    },
    {
      title: 'PRODUCTOS',
      url: '/products'
    },
    {
      title: 'PROCESOS',
      url: '/processes'
    },
    {
      title: 'CONTACTO',
      url: '/contact'
    },
  ];