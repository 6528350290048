import React, { Component } from "react";

import "./styles.scss";

class Content extends Component {

  render() {
    const { body } = this.props;

    return <div className="content">{body}</div>;
  }
}

export default Content;
