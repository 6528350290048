import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Notifications, {notify} from 'react-notify-toast';
import http from "../../../utils/http";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 500,
    maxWidth: 500,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
});

const myColor = { background: '#13dd86', text: "#FFFFFF" };

class AddressForm extends PureComponent {
  state = {
    name: '',
    open: false,
    variant: 'success',
    message: ''
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      http.get(`category/${match.params.id}`, false).then(res => {
        if (res.success) this.setState({ name: res.category.name });
      })
    }
  }

  handleChange = name => event => {
    let { value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  handleClose() {
    const { open } = this.state;
    this.setState({open: !open})
  }

  send() {
    const { match, history } = this.props;
    const { name } = this.state;
    if (match.params.id) {
      http.put(`category/${match.params.id}`, {name}, false).then(res => {
        if (res.success) {
          notify.show('Operación exitosa!', "custom", 5000, myColor);
          history.goBack();
        }
      })
    } else {
      http.post('category', {name}, false).then(res => {
        if (res.success) {
          notify.show('Operación exitosa!', "custom", 5000, myColor);
          history.goBack();
        }
      })
    }
  }

  render() {
    const { classes } = this.props;
    const { name } = this.state;

    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Categoría
        </Typography>
        <Notifications />
        {/* <Toastr open={open} variant={variant} message={message} handleClose={this.handleClose}/> */}
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label="Nombre"
              fullWidth
              variant="outlined"
              autoComplete="fname"
              value={name}
              onChange={this.handleChange('name')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="button-container">
              <Button onClick={() => this.send()} variant="contained" color="primary" className="button-form">
                Guardar
                <Icon className={classes.rightIcon}>send</Icon>
              </Button>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AddressForm);
