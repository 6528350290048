import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Header from './components/Global/Header';
import Content from './components/Global/Content';
import Footer from './components/Global/Footer';
import items from './data/topMenu';

import './App.scss';

class App extends Component {
  render() {
    const title = 'El Pionero';
    const { children } = this.props;

    return (
      <div>
        <Helmet>
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        
          <title>{title}</title>
        </Helmet>
        <Header
          items={items}
        />
        <Content body={children} />
        <Footer />
      </div>
    );
  }
}

export default App;
