import React, { PureComponent } from "react";
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

import "./styles.scss";

const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
};

class CustomDrawer extends PureComponent {
  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {
    const { items, menuVisible, changeMenu } = this.props;

    const sideList = (
      <div>
        <div className="logo-drawer">
          <Link offset="0" to="/admin/products">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <Divider />
        <List>
          {items.map((item, index) => (
            <Link key={`dr-${index}`} offset="0" to={item.url}>
              <ListItem button key={item.title}>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            </Link>
          ))}
        </List>
      </div>
    );

    return (
      <div>
        <Drawer open={menuVisible} onClose={() => changeMenu()}>
          <div
            tabIndex={0}
            role="button"
            onClick={() => changeMenu()}
            onKeyDown={() => changeMenu()}
          >
            {sideList}
          </div>
        </Drawer>
      </div>
    );
  }
}

export default withStyles(styles)(CustomDrawer);
