import React, { Component } from "react";

import "./styles.scss";

class Content extends Component {

  render() {
    const { body } = this.props;

    return(
    <div className="content">
      <div className="content-wrapper">
        {body}
      </div>
    </div>
    )
  }
}

export default Content;
