import finish from "../assets/images/finish.png";
import fabric from "../assets/images/fabric.png";
import design from "../assets/images/design.jpg";

export default [
	{
		name: 'Diseño',
		description: "El área especializada en analizar las tendencias de cada temporada, donde se desarrollan los tejidos para su posterior fabricación.",
		img: design
	},
	{
		name: 'Tejido',
		description: "La variedad de máquinas circulares nos permite fabricar diversas telas en tejidos de punto en poliéster y/o algodón.",
		img: fabric
	},
	{
		name: 'Acabado',
		description: "La importancia del acabado es fundamental para nosotros, tanto para el aspecto visual como en la durabilidad de los tejidos.",
		img: finish
	},
];
