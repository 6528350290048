import React, { PureComponent } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import TextField from '@material-ui/core/TextField';
import http from "../../utils/http";
import Notifications, { notify } from 'react-notify-toast';
import "./styles.scss";

const { REACT_APP_RECAPTCHA } = process.env;

const myColor = { background: '#13dd86', text: "#FFFFFF" };

class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      recaptcha: null,
      name: '',
      email: '',
      message: '',
      phone: '',
    };
  }

  onChange(value) {
    this.setState({ recaptcha: value });
  }

  handleChange = name => event => {
    const { value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  send() {
    const {
      recaptcha,
      name,
      email,
      message,
      phone
    } = this.state;

    if (recaptcha)
      http.post('contact', { name, email, message, phone }, false).then(res => {
        if (res.success) {
          notify.show('Correo enviado!', "custom", 5000, myColor);
          this.setState({ name: '', email: '', message: '', phone: '', recaptcha: null })
        };
      })
  }

  render() {
    const {
      name,
      email,
      message,
      phone
    } = this.state;
    return (
      <div className="contact-form">
        <Notifications />
        <div className="contact-form-container">
          <div className="title">CONTACTO</div>
          <div className="info-contact">
            <div>Estamos aqui para ayudarte.</div>
            <div>Envíanos un mensaje.</div>
          </div>
          <div className="input-item">
            <div className="label-form">Nombre</div>
            <TextField
              id="outlined-full-width"
              style={{ margin: 8 }}
              placeholder="Escriba su nombre y apellido"
              fullWidth
              margin="normal"
              variant="outlined"
              value={name}
              onChange={this.handleChange('name')}
            />
          </div>
          <div className="input-item">
            <div className="label-form">Correo</div>
            <TextField
              id="outlined-full-width"
              style={{ margin: 8 }}
              placeholder="Escriba su correo"
              fullWidth
              margin="normal"
              variant="outlined"
              value={email}
              onChange={this.handleChange('email')}
            />
          </div>
          <div className="input-item">
            <div className="label-form">Teléfono</div>
            <TextField
              id="outlined-full-width"
              style={{ margin: 8 }}
              placeholder="Medio opcional de contacto"
              fullWidth
              margin="normal"
              variant="outlined"
              value={phone}
              onChange={this.handleChange('phone')}
            />
          </div>
          <div className="input-item">
            <div className="label-form">Mensaje</div>
            <TextField
              id="outlined-full-width"
              multiline
              rowsMax="4"
              style={{ margin: 8 }}
              placeholder="Escriba aqui su mensaje"
              fullWidth
              margin="normal"
              variant="outlined"
              value={message}
              onChange={this.handleChange('message')}
            />
          </div>
          <ReCAPTCHA
            sitekey={REACT_APP_RECAPTCHA}
            onChange={value => this.onChange(value)}
          />
          <div className="contact-button-container">
            <button onClick={() => this.send()} className="contact-button">Enviar</button>
          </div>
        </div>
        <div className="email-container">
          <div className="label-form">E-mail:</div>
          <div className="email-text">ventas@textilelpionero.com</div>
        </div>
      </div>
    );
  }
}

export default Home;
