import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Drawer from './components/Drawer';
import Content from './components/Global/Content/Admin';
import Header from './components/Global/Header/headerAdmin';
import items from './data/adminMenu';


class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuVisible: false
    };
  }

  changeMenu() {
    const { menuVisible } = this.state;
    this.setState({ menuVisible: !menuVisible });
  }

  render() {
    const title = 'Admin | El Pionero';
    const { children } = this.props;
    const { menuVisible } = this.state;

    return (
      <div>
        <Helmet>
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>
          <title>{title}</title>
        </Helmet>
        <Header changeMenu={this.changeMenu.bind(this)}/>
        <Content body={children} />
        <Drawer changeMenu={this.changeMenu.bind(this)} menuVisible={menuVisible} items={items} />
      </div>
    );
  }
}

export default Admin;
