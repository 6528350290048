import React, { Component } from 'react';
import { Route } from 'react-router-dom';

// Components
import Admin from '../Admin';
import Home from '../views/admin/Home';
import Products from '../views/admin/Products';
import Categories from '../views/admin/Categories';
import FormCategory from '../views/admin/Categories/Form';
import FormProducts from '../views/admin/Products/Form';

class AppRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <Admin>
        <Route exact path={`${match.path}/`} component={Home} />
        <Route exact path={`${match.path}/products/`} component={Products} />
        <Route exact path={`${match.path}/product/:id/`} component={FormProducts} />
        <Route exact path={`${match.path}/product/`} component={FormProducts} />
        <Route exact path={`${match.path}/categories/`} component={Categories} />
        <Route exact path={`${match.path}/category/:id/`} component={FormCategory} />
        <Route exact path={`${match.path}/category/`} component={FormCategory} />
      </Admin>
    )
  }
}

export default AppRoutes;
